/* gatsby-browser.js */
// import React from "react"
import './src/styles/global.css'
// import { Script } from "gatsby"

export const onRouteUpdate = () => {
  //console.log("onRouteUpdated") // this works
  //
  // var addScript = document.createElement('script');
  // addScript.setAttribute('src', 'https://static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js');
  // addScript.setAttribute('id', 'signupScript');
  // document.body.appendChild(addScript);
  // console.log('new script');
  // let formDiv = document.getElementById("formDiv");
  // formDiv.innerHTML += `<div class="ctct-inline-form mtop-30 relative" data-form-id="1a6b0fa3-5d01-42a8-84e3-a38c1da3dd6f"></div>`
}
