exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-wp-dining-js": () => import("./../../../src/templates/WpDining.js" /* webpackChunkName: "component---src-templates-wp-dining-js" */),
  "component---src-templates-wp-gallery-archive-js": () => import("./../../../src/templates/WpGalleryArchive.js" /* webpackChunkName: "component---src-templates-wp-gallery-archive-js" */),
  "component---src-templates-wp-gallery-js": () => import("./../../../src/templates/WpGallery.js" /* webpackChunkName: "component---src-templates-wp-gallery-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-post-archive-js": () => import("./../../../src/templates/WpPostArchive.js" /* webpackChunkName: "component---src-templates-wp-post-archive-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WpPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */),
  "component---src-templates-wp-press-archive-js": () => import("./../../../src/templates/WpPressArchive.js" /* webpackChunkName: "component---src-templates-wp-press-archive-js" */),
  "component---src-templates-wp-room-type-js": () => import("./../../../src/templates/WpRoomType.js" /* webpackChunkName: "component---src-templates-wp-room-type-js" */)
}

